// src/components/FAQ.js
import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: 'Quels niveaux scolaires couvrez-vous avec vos services ?', answer: "Nos cours sont offerts aux élèves de la 6ème jusqu'à la 1ère."},
    { question: 'Les séances de tutorat sont-elles individuelles ou en groupe ?', answer: "Sur notre plateforme SavantMath nous offrons que des séances individuelles , votre enfant va bénificier d'un cours personnalisé et un suivi complet durant cette période avec un professeur exprimenté ." },
    { question: 'Comment puis-je inscrire mon enfant à vos services ?', answer: "Vous pouvez toujours se bénifcier d'une séance d'essai gratuite en cliquant sue le bouton 'ESSAYEZ UN COURS GRATUIT D'1H' ou 'commencez' , repmlir la formulaire , choisir un horaire qui vous convient et renconter nos prof ! " },
    { question: ' Où se déroulent les séances ?', answer: 'Toutes nos séances de cours se déroulent en ligne, via une plateforme de visioconférence sécurisée' },
    { question: 'Puis-je choisir le tuteur pour mon enfant ?', answer: "Lors de la premiére séance de rencontre , notre expert pédagogique va évaluer et identifier les connaissance et les besions de votre enfant et un tuteur va être assigné à votre enfant en se basant sur les lacunes ,les besoins de votre enfants et les capacités de nos tuteurs.Notre objective est d'offrir du valeur !" }
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq-section">
      <h2>Questions Fréquemment Posées</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-card" onClick={() => handleToggle(index)}>
            <div className="faq-question">
              {faq.question}
              <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>&#9660;</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
