// src/components/About.js
import React from 'react';
import './SvgSeperation.css';
import wave from "../wave-haikei.svg"

const Seperation = () => {
  return (
    <section >
 <div>
 <div className="svg-container">
        <img src={wave} alt="SVG" className="svg-image-above" />
      </div>
 </div>
    </section>
  );
};

export default Seperation;