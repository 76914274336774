// src/components/Services.js
import React, { useEffect, useRef, useState } from 'react';
import './Services.css';
import logo from '../logo1.png';
import image1 from '../assets/clip-pointing-girl1-ezgif.com-resize.gif'
import image2 from '../assets/clip-39-ezgif.com-resize.gif'


const ServiceCard = ({ logo, title, initialDetails, moreDetails }) => {
  const [expanded, setExpanded] = useState(false);

  return (
   
    <div className={`service-card ${expanded ? 'expanded' : ''}`}>
      <div className="card-header">
        <div className='heading-card'>
          <div>
            <img src={logo} alt={title} className="card-logo animate fadeIn" />
          </div>
          <div className="card-title-details">
            <h3 className="animate slideInDown">{title}</h3>
          </div>
          <div>
            <button onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Voir Moins' : 'Voir Plus'}
            </button>
          </div>
        </div>
        <div className="text-align">
          <p className="initial-details animate slideInUp">{initialDetails}</p>
        </div>
      </div>

      {expanded && (
        <div className="card-details animate zoomIn">
          <p>{moreDetails}</p>
        </div>
      )}
    </div>
    
  );
};

const Services = () => {

  return (
    <section id="services" className="services-section-container">
      <div className='heading-text'>
        <h2 className="animate slideInDown">Comment les cours fonctionnent ?</h2>
      </div>
      <div className='text-below-heading'>
        <p className="animate slideInUp">Ce qui rend nos séances unique !</p>
      </div>
      <div className='container-service'>
        <div className='image-2'>
          <img src={image2} alt="Spanish Lesson" />
        </div>
      
      <div className="services-container">
      
        <ServiceCard
          logo={logo}
          title="Cours d'été Personnalisés"
          initialDetails="Les Cours d’été à distance permettent aux collégiens et lycéens de profiter 
          des grandes vacances pour réviser ou se remettre à niveau avant la rentrée."
          moreDetails="Encadrée par des professeurs en exercice, la formule reprend l’ensemble 
          du programme de l’année. Les révisions insistent sur les points à maîtriser impérativement, 
          avec une pédagogie classique à difficulté progressive. Les échanges avec les professeurs et
          les corrections se font à distance ; l’élève peut ainsi réviser à son propre rythme, quel
          que soit son lieu de vacances."/>
      
     
        <ServiceCard
          logo={logo}
          title="Programme de Révision Intensive"
          initialDetails="Une immersion totale dans les concepts essentiels pour une maîtrise rapide."
          moreDetails="Plongez-vous dans notre programme de révision intensive, où chaque séance est 
          soigneusement planifiée pour aider votre enfant à maîtriser rapidement les concepts fondamentaux.
           Avec un accent sur la pratique et la consolidation, votre enfant serez prêt à relever tous les défis 
           mathématiques qui se présentent à lui."
        />
      
        <ServiceCard
          logo={logo}
          title="Suivi Individualisé "
          initialDetails="Un accompagnement sur mesure pour chaque étape de votre parcours d'apprentissage."
          moreDetails=" Recevez un suivi individualisé et des retours personnalisés à chaque étape du
          parcours d'apprentissage. Nos professeurs dévoués travailleront en étroite collaboration avec vous pour
           identifier les points forts et les domaines à améliorer de votre enfant , lui aidant ainsi à progresser de manière
            significative."
        />

        <ServiceCard
          logo={logo}
          title="Ressources Pédagogiques Interactives"
          initialDetails="Explorez des outils d'apprentissage innovants pour une compréhension approfondie."
          moreDetails="Accédez à une gamme d'outils pédagogiques interactifs, y compris des exercices pratiques,
           des vidéos explicatives et des quiz en ligne, pour renforcer la compréhension des concepts
            mathématiques. Notre approche d'apprentissage multimodale garantit une expérience d'apprentissage
             engageante et efficace."/>

        <ServiceCard
          logo={logo}
          title="Flexibilité Horaires"
          initialDetails="Des horaires flexibles pour s'adapter à votre emploi du temps chargé."
          moreDetails="Nous offrons une flexibilité totale en matière d'horaires. Que vous préfériez des 
           séances matinales ou des cours en soirée, nous nous engageons à vous offrir un 
           service qui s'adapte à votre rythme de vie."/>

      </div>
      <div className='image-1'>
      <img src={image1} alt="Spanish Lesson" />
      </div>
    </div>
    </section>
  );
};

export default Services;


