import React from 'react';
import { useInView } from 'react-intersection-observer';
import './AnimationSection.css'

const AnimatedSection = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust this value as needed
    triggerOnce: true, // Trigger only once
  });

  return (
    <div
      ref={ref}
      className={`section ${inView ? 'animate' : ''}`}
    >
      {children}
    </div>
  );
};

export default AnimatedSection;
