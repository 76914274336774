// src/components/Blog.js
import React from 'react';
import './Blog.css';
const Blog = ({ image}) => {
  return (
    <section id="blog" className="blog-section">
      <div className="blog-container">
        <div className="blog-image">
          <img src={image} alt="Blog" />
        </div>
        <div className="blog-content">
          <p> <q>L'été est une période précieuse pour les enfants, offrant une pause bienvenue et 
        des opportunités d'apprentissage et de développement personnel. Les cours d'été aident à
         prévenir la perte d'apprentissage et permettent de combler les lacunes scolaires, tout en
          éveillant de nouvelles passions. L'encadrement parental et éducatif joue également un rôle
           crucial en maintenant un équilibre entre liberté et structure, en stimulant la curiosité 
           naturelle des enfants par des activités enrichissantes. En combinant ces éléments, nous
            pouvons transformer les vacances estivales en une période d'épanouissement et de découverte,
             préparant ainsi les enfants pour la prochaine année scolaire et au-delà.</q> - Tayeb chaker</p>
             <a  href="https://cal.com/chakertayeb/seancegratuite" target='blank' ><button className="schedule-button" >PROFITEZ DES GRANDES VACANCES</button></a>
        </div>
      </div>
    </section>
  );
};

export default Blog;

