import React from 'react';
import FormPage from './components/FormPage';

const PostPayment = () => {
  return (
  <FormPage/>
  );
};

export default PostPayment;




