import React from 'react';
import './SvgSeperation2.css';
import wave from "../wave-haikei copy.svg"

const Seperation = () => {
  return (
    <section >
 <div>
 <div className="svg-container">
        <img src={wave} alt="SVG" className="svg-image" />
      </div>
 </div>
    </section>
  );
};

export default Seperation;