// src/App.js
import React from 'react';
import './App.css';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Blog from './components/Blog';
import Timeline from './components/Timeline/Timeline';
import Pricing from './components/Pricing'; 
import FAQ from './components/FAQ'; 
import image from './chaker.jpg'
import Seperation from './components/SvgSeperation'
import Seperation2 from './components/SvgSeperation2'
import Testi from './components/Testi'
import Whyus from './components/Whyus'
import AnimatedSection from './components/Animationsection';
import TimelineResponsive from './components/Timeline/TimelineResponsive'





function App() {
  return (
    <div className="App">

      <Header />
      <main>
        <AnimatedSection>
        <About />
        </AnimatedSection>
        
        <AnimatedSection>
      
        <Services />
        </AnimatedSection>
  
        


        <AnimatedSection>
        <TimelineResponsive/>
        </AnimatedSection>

        <Seperation/>
        <Blog 
        image={image}
      />
      <Seperation2/>
        <Testi />
        
        
        <Whyus/>
        
        
        <AnimatedSection>
        <Pricing/>
        </AnimatedSection>
        <FAQ/>
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
