// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 SavantMath. All rights reserved.</p>
    <div className='footer-container' >
      <p> <strong>Télephone :</strong> +33 4 65 84 93 08</p>
      <p> <strong>Email :</strong> contact@savantmath.com</p>
    </div>

    </footer>
  );
};

export default Footer;
