// src/components/About.js
import React from 'react';
import './About.css';
import Chaker from "../chaker.jpg"
import gifile1 from '../assets/Animation-about.gif'



const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-left">
        <h2>Prenez des cours d'été de Maths en visio avec un professeur exprimenté</h2>
        <p>Des cours d'été sur mesure pour des résultats éclatants. Rejoignez-nous pour un apprentissage interactif et réussissez vos maths!"</p>

      </div>
      <div className="about-right">
        {/* You can add an image or any content for the right block here */}
        <img src={gifile1} alt="Spanish Lesson" />
        </div>
        <div className='about-left'>
        <a  href="https://cal.com/chakertayeb/seancegratuite" target='blank' ><button className="schedule-button-about" >ESSAYEZ UN COURS GRATUIT D'1H</button></a>
      </div>
    </section>
  );
};

export default About;
