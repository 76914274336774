// src/components/Header.js
import React from 'react';
import './Header.css';
import brand from '../brandlogo2.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <header className='section-header'>
      <div className='container-header'>
        <div className="logo">
          <img src={brand} alt="Angela Spanish Teacher Logo" />
        </div>
        <nav>

        
          <ul>
            <li><a href="#contact">Contact</a></li>
          </ul>

          <a  href="https://cal.com/chakertayeb/seancegratuite" target='blank' ><button className="schedule-button-header"  >Commencez!</button></a>
        </nav>
      </div>
        <div className="header-contact">
          <FontAwesomeIcon icon={faPhoneAlt} />
          <span className="phone-number">+33 4 65 84 93 08</span>
        </div>
    </header>
  );
};

export default Header;

