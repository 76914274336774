// src/components/Contact.js
import React from 'react';
import './Contact.css';
import ImageContact from '../assets/ImageContact.png'

const Contact = () => {
  return (
    <section id="contact" >
      <h2>Contactez nous !</h2>
      <div className='container-contact'>
      <form className='contact-section'>
      <div>
          <input type="text" name="name" placeholder="Nom " />
        </div>
        <div>
          <input type="email" name="email" placeholder="Email"/>
        </div>
        <div>
          <textarea name="message" placeholder="Votre texte .."></textarea>
       </div> 
        <button type="submit">Envoyer</button>
      </form>
      <div className='image-contact'>
      <img src={ImageContact} alt="Spanish Lesson" />
      </div>
    </div>  
    </section>
  );
};

export default Contact;
