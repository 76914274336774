import React, { useState } from 'react';
import './Timeline.css';
import Lottie from 'lottie-react';
import animationData from '../../assets/Animation - 1717762789545.json'; 
import animationData1 from '../../assets/Animation-timeline.json'; 
import animationData2 from '../../assets/Animation-timeline2.json'; 
import gifFile from '../../assets/Animation-timeline3.gif';

const SectionLeft = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="sectionContainer mb-10 ml-6">
      <span className="blueCircle absolute flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full -left-3 ring-8 ring-white dark:ring-zinc-900">
        <svg aria-hidden="true" className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11V5a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0V9h2a1 1 0 100-2h-2z" clipRule="evenodd"></path>
        </svg>
      </span>
      <h3
        className="title title-button flex items-center mb-1 text-lg font-semibold text-zinc-900 dark:text-white"
        onClick={toggleVisibility}
      >
        {title}
      </h3>
      <ul className={`text mb-4 text-base font-normal text-zinc-500 dark:text-zinc-400 collapsible-content ${isVisible ? 'expanded' : ''}`}>
        {content.map((sentence, index) => (
          <li key={index}>{sentence}</li>
        ))}
      </ul>
    </div>
  );
};

const SectionRight = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="sectionContainer ml-6">
      <span className="blueCircle absolute flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full -left-3 ring-8 ring-white dark:ring-zinc-900">
        <svg aria-hidden="true" className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11V5a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0V9h2a1 1 0 100-2h-2z" clipRule="evenodd"></path>
        </svg>
      </span>
      <h3
        className="title title-button flex items-center mb-1 text-lg font-semibold text-zinc-900 dark:text-white"
        onClick={toggleVisibility}
      >
        {title}
      </h3>
      <ul className={`text mb-4 text-base font-normal text-zinc-500 dark:text-zinc-400 collapsible-content ${isVisible ? 'expanded' : ''}`}>
        {content.map((sentence, index) => (
          <li key={index}>{sentence}</li>
        ))}
      </ul>
    </div>
  );
};

const sentences1 = [
  "• Revoir le programme de l'année écoulée solidifie les connaissances acquises.",
  "• Clarifier les concepts mal compris.",
  "• Une révision structurée par matières et chapitres aide à couvrir tous les sujets."
];

const sentences2 = [
  "• Faire un bilan des connaissances pour repérer les points faibles.",
  "• Utiliser des tests ou des auto-évaluations.",
  "• Identifier et combler les lacunes est crucial pour éviter des obstacles à l'apprentissage futur."
];

const sentences3 = [
  "• Se préparer en révisant le programme précédent et en se fixant des objectifs clairs",
  "• Prépare le terrain pour les nouvelles leçons de l'année suivante.",
  "• La confiance en soi est essentielle pour la réussite scolaire."
];

const ReactComponent = () => {
  return (
    <section id='Timeline' className='section-timeline mobile-only'>
      <div>
        <h2>Quelles sont les objectives ?</h2>
      </div>
      <div className='text-below-heading'>
        <p>Préparation , Révision ,Confiance !</p>
      </div>
      <div className="relative dark:border-zinc-700">
        <div className="timeline-line"></div>
      </div>
      <div className="container-style">
        <div className='block-image-text-1'>
          <SectionLeft
            title="Reprendre le programme de l'année"
            content={sentences1}
          />
          <div className='image-container'>
            <div className='image'>
              <Lottie animationData={animationData1} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>

        <div className='block-image-text'>
          <div className='image-container'>
            <div className='image'>
              <Lottie animationData={animationData2} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <SectionRight
            title="Combler d'éventuelles lacunes"
            content={sentences2}
          />
        </div>

        <div className='block-image-text-hor'>
          <SectionLeft
            title="Se remettre en confiance avant la rentrée"
            content={sentences3}
          />
          <div className='image-container'>
            <div className='image'>
              <img src={gifFile} alt="Background Animation" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReactComponent;
