import React from 'react';
import './Whyus.css';
import image1 from '../assets/5293.jpg'
import Gifile1 from '../assets/Animation-Whyus1.gif'
import Gifile2 from '../assets/Animation-whyus2.gif'
import Gifile3 from '../assets/Animation-whyus3.gif'
import AnimatedSection from './Animationsection'



const ThreeReasons = () => {
    return (
        <div className="three-reasons">
            <h3>Pourquoi nous choisir ?</h3>
            <h1>3 bonnes raisons</h1>
            
            <div className="reasons">
            <AnimatedSection>
                <div className="reason">
                    <div className='image-vag'>
                    <img src={Gifile1} alt="Ville de moins de 5000 habitants" />
                    </div>
                    <h3>Si vous voulez profiter des grandes vacances</h3>
                    <p>Nos professeurs en visio sont la solution pour aider votre enfant pour réviser ou se remettre à niveau avant la rentrée.</p>
                </div>
            </AnimatedSection>
            <AnimatedSection>
                <div className="reason">
                <div className='image-vag'>
                    <img src={Gifile2} alt="Ville de moins de 5000 habitants" />
                    </div>
                    <h3>Si vous avez un budget de moins de 150 €/mois</h3>
                    <p>nos forfaits mensuels illimités vous permettent de vous concentrer sur les questions pédagogiques</p>
                </div>
            </AnimatedSection>
            <AnimatedSection>
                <div className="reason">
                <div className='image-vag'>
                    <img src={Gifile3} alt="Ville de moins de 5000 habitants" />
                    </div>
                    <h3>Si vous cherchez un professeur de maths exprimenté</h3>
                    <p>tous nos professeurs de maths sont disponibles du lundi au dimanche et de 10h à 22h</p>
                </div>
            </AnimatedSection>    
            </div>
        </div>
    );
};

export default ThreeReasons;

