// src/components/Pricing.js
import React from 'react';
import './Pricing.css';
import image1 from '../assets/Image-pricing.png'
import image2 from '../assets/Image-pricing2.png'


const Pricing = () => {
  return (
    <section id="pricing" className="pricing-section">
    <div className="web-section">
      <h1>Nos Tarifs !</h1>
      <p>Des forfaits </p>
      <h2>Clairs & simples</h2>
    </div>
      <div className="pricing-container">
        <div className="pricing-card">
          <div className="pricing-header">
            <h3>Suivi Complet</h3>
            <img src={image1} alt='image'  />
            <div className='container-price'>
            <div className='price-1'>
            <p>250€</p>
            </div>
            <div className='price-2'>
            <p>/tout l'été</p>  
            </div>
            </div>
          </div>
          <ul>
            <li>• Cours en illimité</li>
            <li>• Cours 1 à 1</li>
            <li>• Diagnostic d'entrée</li>
            <li>• Plan de travail</li>
            <li>• Un rapport de suivi hebdomadaire</li>

          </ul>
          <a href='https://knct.me/u6Gwp6mn8' target='blank'><button className="start-button">souscrire</button></a>
        </div>

        <div className="pricing-card">
          <div className="pricing-header">
            <h3>Soutien Personalisé</h3>
            <img src={image2} alt='image'  />
            <div className='container-price'>
            <div className='price-1'>
            <p>150€</p>
            </div>
            <div className='price-2'>
            <p>/mois</p>  
            </div>
            </div>
          </div>
          <ul>
            <li>• Cours en illimité</li>
            <li>• Cours 1 à 1</li>
            <li>• Diagnostic d'entrée</li>
            <li>• Plan de travail</li>
            <li>• Un rapport de suivi hebdomadaire</li>

          </ul>
          <a href='https://knct.me/ggmtzxW-f' target='blank'><button className="start-button">souscrire</button></a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
