// src/components/FormPage.js
import React, { useState } from 'react';
import './FormPage.css'
import axios from 'axios';




const FormPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    childFirstName: '',
    childLastName: '',
    childGrade: '',
    additionalInfo: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios.post('https://savantmath.com/backend/invitesender.php', formData)
      .then(response => {
        alert('Email envoyé avec succés ! Consulter votre Boite mail.');
        alert('Bienvenue dans Votre espace SavantMath');



      })
      .catch(error => {
        console.error('There was an error sending the email!', error);
      });
  };

  return (
    <div className="form-container">
      <h2>Completer cette formulaire !</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="firstName"
          placeholder="Nom"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Prénom"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Numéro téléphone"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="childFirstName"
          placeholder="Nom de votre enfant"
          value={formData.childFirstName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="childLastName"
          placeholder="Prénom de votre enfant"
          value={formData.childLastName}
          onChange={handleChange}
          required
        />
        <div>
          <label>Classe:</label>
          <input
            type="radio"
            name="childGrade"
            value="grade1"
            checked={formData.childGrade === 'grade1'}
            onChange={handleChange}
            required
          /> 6ème
          <input
            type="radio"
            name="childGrade"
            value="grade2"
            checked={formData.childGrade === 'grade2'}
            onChange={handleChange}
            required
          /> 5ème
          <input
            type="radio"
            name="childGrade"
            value="grade3"
            checked={formData.childGrade === 'grade3'}
            onChange={handleChange}
            required
          /> 4ème
            <input
            type="radio"
            name="childGrade"
            value="grade4"
            checked={formData.childGrade === 'grade3'}
            onChange={handleChange}
            required
          /> 3ème
            <input
            type="radio"
            name="childGrade"
            value="grade5"
            checked={formData.childGrade === 'grade3'}
            onChange={handleChange}
            required
          /> 2nde
            <input
            type="radio"
            name="childGrade"
            value="grade3"
            checked={formData.childGrade === 'grade3'}
            onChange={handleChange}
            required
          /> 1ère
        </div>
        
        <textarea
          name="additionalInfo"
          placeholder="Additional Information (optional)"
          value={formData.additionalInfo}
          onChange={handleChange}
        />
        <button type="submit" >Submit</button>
        </form>
    </div>
  );
};

export default FormPage;
