import React, { useState, useEffect } from 'react';
import Timeline from './Timeline';
import TimelineDesktop from './TimelineDesktop';

const LandingPage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 479);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 479);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div>
      {isDesktop ? <TimelineDesktop /> : <Timeline />}
    </div>
  );
};

export default LandingPage;
