import React, { useState } from 'react';
import './Testi.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


const testimonials = [
    {
      text: "professeur à l'écoute et revoit avec mo les exercices et les leçons , ce qui nous permet de progresser et de bien consolider mes connaissances. Merci!!!!! très satisfait.",
      name: 'Jane Dupont',
      role: 'élève de 2nde',
      avatar: 'https://example.com/avatar1.jpg',
    },
    {
      text: "Je suis très satisfaite des cours, prof à l'écoute c'est génial franchement merci je sens que je comprends mieux mes cours maintenant !",
      name: 'christine lagard',
      role: 'élève de 3ème',
      avatar: 'https://example.com/avatar2.jpg',
    },
    {
      text: 'Le professeur se montre très agréable, compréhensif et pédagogue, simplement le process de cours en distanciel ne plait pas à notre fille.Merci à vous et à lui !',
      name: 'Cedric ebel',
      role: 'Parent de Nadine',
      avatar: 'https://example.com/avatar2.jpg',
    },
    {
      text: '21h c est tard mais c était bien expliqué et ma fille a bien suivi le cours de math avec le professeur. Merci',
      name: 'Najet Abdouli',
      role: 'Parent de Lina',
      avatar: 'https://example.com/avatar2.jpg',
    }
    // Add more testimonial objects here
  ];

  const TestimonialCard = ({ testimonial, handleSwipe }) => {
    return (
      <section className='section-content'>
      <div className="testimonial-card">
        <button className="swipe-button left" onClick={() => handleSwipe('left')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="testimonial-content">
          <p>{testimonial.text}</p>
        </div>
        <div className="testimonial-author">
          <img src={testimonial.avatar} alt={testimonial.name} />
          <div>
            <h4>{testimonial.name}</h4>
            <p>{testimonial.role}</p>
          </div>
        </div>
        <button className="swipe-button right" onClick={() => handleSwipe('right')}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      </section>
    );
  };
  
  const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handleSwipe = (direction) => {
      if (direction === 'left') {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
      } else {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
      }
    };
  
    return (
      <section className="testimonials">
        <h2>Que disent mes élèves ?</h2>
        
        <h3>Plus de 2000 heures de Tutorat</h3>
        <div className="testimonial-container">
          <div className="testimonial-card-container">
            {testimonials.length > 0 ? (
              <TestimonialCard testimonial={testimonials[currentIndex]} handleSwipe={handleSwipe} />
            ) : (
              <p>No testimonials available.</p>
            )}
          </div>
        </div>
      </section>
    );
  };
  
  export default Testimonials;